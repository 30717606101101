<template>
    <div>
        <div class="d-flex justify-content-between">
            <h5>Intl-input-number-Demo</h5>
            <span>Reaming : {{ totalLeft }}</span>
            <b-button variant="info" class="text-white" @click="getNumbers" :disabled="allNumber.length > 0">Get
                Numbers</b-button>
            <span>Corrupted : {{ totalCorrupted }}</span>
            <b-button variant="success" class="text-white" @click="updateNumbers"
                :disabled="allNumber.length < 0">update Numbers</b-button>
        </div>
        <div class="mt-2 row">
            <div :style="{ color: phone.countryCode == 'Unknown' ? 'red' : 'inherit' }" class="d-inline col-md-6 mt-2"
                v-for="(phone, index) in allNumber" :key="'phone' + index">
                <span>{{ phone.id }}</span>
                <input type="tel" class="mr-3 d-inline" v-model="phone.phone"
                    @input="handlePhoneNumberChange($event, index)" />
                <p class="mr-3 d-inline">Phone Number: {{ phone.phoneNumber }}</p>
                <p class="d-inline">Country Code: {{
                    phone.countryCode }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    name: 'input-number',
    data() {
        return {
            allNumber: [],
            totalLeft: '',
            totalCorrupted: ''
        };
    },
    methods: {
        handlePhoneNumberChange(event, index) {
            const inputValue = event.target.value;
            const phoneNumber = parsePhoneNumberFromString(inputValue);

            if (phoneNumber) {
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCode = '+' + phoneNumber.countryCallingCode;
                // Update the corresponding phone number and country code in the array
                this.$set(this.allNumber, index, {
                    ...this.allNumber[index],
                    phoneNumber: nationalNumber,
                    countryCode: countryCode
                });
            } else {
                // Reset phone number and country code if input is invalid
                this.$set(this.allNumber, index, {
                    ...this.allNumber[index],
                    phoneNumber: this.allNumber[index].phone,
                    countryCode: 'Unknown'
                });
            }
        },
        getNumbers() {
            this.axios
                .get("old-clients/phone-numbers")
                .then(response => {
                    this.totalCorrupted = response.data.data.total_corrupted;
                    this.totalLeft = response.data.data.total_left;
                    this.allNumber = response.data.data.phone_numbers.map(phone => ({
                        phone: '+' + phone.phone.replace(/\D/g, ''),
                        id: phone.id,
                        client_id: phone.client_id,
                        countryCode: phone.countryCode,
                        country: '', // You can populate this if needed
                        phoneNumber: '', // You can populate this if needed
                        isValidNumber: false,
                        correctedPhoneNumber: '',
                    }));

                    this.allNumber.forEach((phone, index) => {
                        this.handlePhoneNumberChange({ target: { value: phone.phone } }, index);
                    });

                    this.allNumber.sort((a, b) => {
                        if (a.countryCode === "Unknown" && b.countryCode !== "Unknown") {
                            return -1;
                        }
                        if (a.countryCode !== "Unknown" && b.countryCode === "Unknown") {
                            return 1;
                        }
                        return 0;
                    });

                    // Initialize intlTelInput for each input
                    this.$nextTick(() => {
                        this.allNumber.forEach((phone, index) => {
                            const input = document.querySelectorAll('input[type="tel"]')[index];
                            intlTelInput(input, {
                                utilsScript: 'libphonenumber-js/utils.js'
                            });
                        });
                    });
                })
                .catch(error => {
                });
        },
        updateNumbers() {
            let _vm = this;
            const updatedNumbers = _vm.allNumber.map(phone => ({
                id: phone.id,
                client_id: phone.client_id,
                phone: phone.phoneNumber,
                prefix: phone.countryCode
            }));
            _vm.axios
                .post("old-clients/validate-phone-numbers", { phone_numbers: updatedNumbers })
                .then(function (response) {
                    _vm.allNumber = [];
                    _vm.totalLeft = '';
                    _vm.totalCorrupted = '';
                })
                .catch(function () {
                });
        }
    }
}
</script>
