<template>
  <div id="lead-assign">
    <transition name="modal">
      <div class="modal-mask show">
        <div class="modal modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content ">
              <div class="modal-header p-2 mr-2">
                <h5 class="modal-title">Client Assignment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="overflow-y: visible;">
                <label for="type" class=" form-control-label">User</label><span class="p-1 text-danger">*</span>
                <v-select id="assignUser" name="assignUser" title="Select an user" label="name" v-model="selectedUser"
                  :options="usersList" class="dropdoenselect dropdown-scroll">
                  <option>{{ usersList.name }}</option>

                </v-select>
              </div>
              <div class="modal-footer p-2">
                <button type="button" @click="assignAgent" class="btn btn-success border-success active">Assign
                  Agent</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { _ } from "vue-underscore";
export default {
  name: "AssignUser",
  data() {
    return {
      usersList: [],
      selectedUser: null,
    };
  },
  props: {
    leadIds: [],
    oldClient_id: String,
    oldAssignClient: Boolean,
    getAll: {
      type: Function,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    // department() {
    //     return this.user.department;
    // },
  },
  methods: {
    assignAgent() {
      const _vm = this;
      _vm.axios
        .get(`old-clients/assign/` + _vm.oldClient_id + `/` + _vm.selectedUser.id)
        .then(function (response) {
          _vm.oldAssignClient = false;
          _vm.getAll();
          _vm.$emit('close');
        })
        .catch(function () { });
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/department-wise-user/" + this.user.department_id)
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () { });
    },
  },
  mounted() {
    this.getUserList();
  },
};

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.v-toast .v-toast--bottom {
  z-index: 9999 !important;
}

.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
