import { render, staticRenderFns } from "./Assign-model.vue?vue&type=template&id=5e2b20b6&scoped=true"
import script from "./Assign-model.vue?vue&type=script&lang=js"
export * from "./Assign-model.vue?vue&type=script&lang=js"
import style0 from "./Assign-model.vue?vue&type=style&index=0&id=5e2b20b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2b20b6",
  null
  
)

export default component.exports