<template>
  <div id="Call-modal">
    <transition name="modal">
      <div class="modal-mask show">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header p-0 pt-1">
                <div class="d-flex col-md-11 p-0">
                  <h5 class="mr-5 mt-3 col-md-4">{{ callData.first_name + ' ' + callData.last_name }} ({{ callData.id
                    }})</h5>
                  <div class="d-flex  form-group col-md-7">
                    <div class="ml-5 mr-2 col-md-6 mt-2">
                      <select class="form-dropdown mr-2  float-left au-input au-input--full form-control" v-model="to">
                        <option v-for="(contact, index) in callData.old_phones" :key="index"
                          :value="contact.prefix + contact.phone"> {{
                            contact.prefix }} {{ maskContactValue(contact.phone) }}</option>
                      </select>
                    </div>
                    <div class="mt-2">
                      <!-- <button class="btn btn-success"> -->
                      <!-- <a :href="'tel:' + to" class="text-decoration-none  text-white">
                        <button class="btn btn-success btn-lg">Make Call</button>
                      </a> -->
                      <span v-if="rc_widget == true"><a :href="'tel:' + to"
                          class="text-decoration-none  text-white"><button :disabled="isButtonDisabled"
                            class="btn bg-success float-left text-white">Make Call</button></a></span>
                      <span><button class="btn btn-success btn-lg" @click="makePhoneCall"
                          :disabled="isButtonDisabled">Make
                          Call</button></span>
                      <!-- </button> -->
                    </div>
                  </div>
                </div>
                <button type="button" class="close pt-0 col-md-1" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" @click="$emit('close')">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="overflow-x: hidden;">
                <table class="table table-borderless table-striped table-earning border-bottom">
                  <thead>
                    <tr style="background-color: #553b9b61">
                      <th class="my-element">Type</th>
                      <th class="my-element">To Phone Number</th>
                      <th class="my-element">Time Initiated</th>
                      <th class="my-element">Result</th>
                      <th class="my-element">Duration</th>
                      <th class="my-element text-center">Recording</th>
                    </tr>
                  </thead>
                  <tbody v-if="callRecordList == ''">
                    <tr v-if="showLoader">
                      <td colspan="12" class="text-center">
                        <div class="loadingio-spinner-spinner-hg7ewyizc2s">
                          <div class="ldio-2hsc4oor189">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="tr-shadow" v-for="(callLog, index) in callRecordList" :key="index">
                      <td>{{ callLog?.direction }}</td>
                      <td>{{ callLog?.to }}</td>
                      <td>{{ callLog?.time }}</td>
                      <td>{{ callLog?.result }}</td>
                      <td>{{ callLog?.duration }}</td>
                      <td class="text-center"><i class="fa-regular fa-circle-play fa-lg"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { _ } from "vue-underscore";
import store from "@/store/index.js";

export default {
  name: "call-model",
  data() {
    return {
      callRecordList: [],
      showLoader: false,
      to: '',
      isButtonDisabled: false,
      rc_widget: localStorage.getItem("rc_widget") === "true" || false,
    };
  },
  props: {
    callData: Object,
  },
  watch: {
    rc_widget(newValue, oldVal) {
      localStorage.setItem("rc_widget", newValue.toString());
    },
  },
  computed: {

  },
  methods: {
    maskContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 2;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue = value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + value.substring(value.length - 3);
      return maskedValue;
    },
    getClientCallHistory() {
      const clientId = this.callData.client_id;

      this.axios
        .get(`/lead-call-logs-old-client?oldClientId=${clientId}`)
        .then((response) => {
          this.callRecordList = JSON.parse(atob(response.data.data));
          this.showLoader = false;
        })
        .catch((error) => { });
    },
    // makePhoneCall() {
    //   const _vm = this;
    //   _vm.axios
    //     .post("/ringout", { to: this.to })
    //     .then(function (response) {
    //     })
    //     .catch(function () { });
    // },
    makePhoneCall() {
      const _vm = this;
      _vm.isButtonDisabled = true;
      store.state.isLoaderShow = true;
      _vm.axios
        .post("/ringout", { to: this.to })
        .then(function (response) {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        });
    },
  },
  mounted() {
    let _vm = this;
    if (_vm.callData.old_phones && _vm.callData.old_phones.length > 0) {
      _vm.to = _vm.callData.old_phones[0].prefix + _vm.callData.old_phones[0].phone;
    }
    _vm.getClientCallHistory();
  },
};

</script>

<style scoped>
.v-toast--bottom {
  z-index: 9999 !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

div#rc-widget.Adapter_root {
  z-index: 9999 !important;
}

#Call-modal .modal-dialog {
  --bs-modal-width: 90% !important;
}

.modal-body {
  max-height: calc(100vh - 125px) !important;
  min-height: calc(100vh - 125px) !important;
  overflow-x: hidden;
}
</style>
