import store from "@/store/index.js";
import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import assignClient from "./Assign-model.vue";
import callClient from "./communication-pages/call-model.vue";
import smsClient from "./communication-pages/sms-modal.vue";
import emailClient from "./communication-pages/email-modal.vue";
import intTelInputDemo from "./intel-input-demo.vue";
import PaginationComponent from "../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "oldClientList",

  data() {
    return {
      oldClientList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      oldAssignClient: false,
      openCallClient: false,
      callData: null,
      openSMSClient: false,
      smsData: null,
      openEmailClient: false,
      emailData: null,
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
      searchDebounce: null,
    };
  },
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    assignClient,
    intTelInputDemo,
    callClient,
    smsClient,
    emailClient,
    PaginationComponent,
  },

  computed: {
    selectAllList() {
      if (this.oldClientList.length > 0) {
        return (
          _.where(this.oldClientList, { isSelected: true }).length ===
          this.oldClientList.length
        );
      }
      return false;
    },
    hasOldClientImportPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "old-client-import";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasOldClientExportPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "old-client-export";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasOldClientAssignPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "old-client-assign";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasOldClientTakePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "old-client-take";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    openOldClientCall(tag) {
      this.openCallClient = true;
      this.callData = tag;
    },
    openOldClientSms(tag) {
      this.openSMSClient = true;
      this.smsData = tag;
    },
    openOldClientEmail(tag) {
      this.openEmailClient = true;
      this.emailsData = tag;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.selectedFile = []; // Clear the selectedFile array
      Array.prototype.forEach.call(files, (item) => {
        this.selectedFile.push(item); // Push each file item to the selectedFile array
      });
    },
    uploadOldClient() {
      store.state.isLoaderShow = true;
      // Check if a file is selected
      if (this.selectedFile && this.selectedFile.length > 0) {
        let _vm = this;
        let fd = new FormData();
        fd.append("xlsx", this.selectedFile[0]);
        // Send a POST request to import airport prices
        _vm.axios.post("/import/old-clients", fd).then(() => {
          _vm.selectedFile = [];
          document.getElementById("file-input").value = null;
          _vm.getAll();
          store.state.isLoaderShow = false;
        });
      } else {
        this.$toast.error("Please select a file first!");
      }
    },
    exportOldClient() {
      // Show loader
      store.state.isLoaderShow = true;
      // Fetch airport prices data
      this.axios.get("/export/old-clients").then((response) => {
        // Create a link element
        let a = document.createElement("a");
        a.href = response.data.data.file;
        a.download = response.data.data.name;
        a.click();
        store.state.isLoaderShow = false;
      });
    },
    openOldClient(Client_id) {
      let _vm = this;
      _vm.oldAssignClient = true;
      _vm.oldClient_id = Client_id;
    },
    search(value) {
      // this.getAll();

      clearTimeout(this.searchDebounce);

      // Set a new debounce timeout
      this.searchDebounce = setTimeout(() => {
        this.getAll(); // Call the getAll function after debounce delay
      }, 300);
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("old-clients" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.oldClientList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    takeClient(k) {
      let _vm = this;
      let _msg = "Are you sure you want to Take this Client ?";

      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios.get("old-clients/take/" + k).then(function (response) {
            _vm.getAll();
            _vm.selectedId = 0;
          });
        })
        .catch(function () {
          _vm.getAll();
        });
    },
  },
  mounted() {
    this.getAll();
  },
};
